import i18n from 'i18next';
import XHR from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEN from '../locales/en.json';
import translationFR from '../locales/fr.json';
  
const resources = {
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  }
};

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng'
};

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(XHR)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: true,
    keySeparator: '.',  // to support nested translations
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
    detection: options,
    supportedLngs: ['fr', 'en'],
  });

export default i18n;