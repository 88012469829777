import React, { memo, useEffect, useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';
import { useDispatch, useSelector } from 'react-redux';

import { geocoder } from '../utils/geocoderUtils';
import { setLoadOption } from '../slices/searchSlice';
import { useTranslation } from 'react-i18next';

const testAreEquals = (prevRoad, nextRoad) => {
  if (prevRoad.value !== nextRoad.value) {
    return false;
  }
  if (prevRoad.typeSearch !== nextRoad.typeSearch) {
    return false;
  }
  return true;
};

const GeocoderInputComponent = ({
  value, 
  typeSearch,
  handleGeocoder, 
  autofocus=false, 
  handlePressInput
}) => {

  const dispatch = useDispatch();
  const {t} = useTranslation();

  const [input, setInput] = useState(value);
  const showPoi = useSelector(state => state.poi.open);
 
  const setGeocoderInput = useDebouncedCallback( async (value, type) => {
    if (value !== null && value !== '' && typeSearch) {
      const response = await geocoder.search(value, type);
      if (response !== undefined) {
        const {results} = response;
        if (results) {
          const options = results.map(feature => 
            ({
              'id': feature.id, 
              'label': feature.name, 
              'point': feature.st_asgeojson.coordinates,
              'type':  feature.type
            }));
          handleGeocoder(options);
        }
      } else {
        return;
      }  
    } else {
      return;
    }
    dispatch(setLoadOption(false));
  }, 500, {
    maxWait: 5000
  });


  const handleGeocoderInput = e => { 
    const textInput = e.target.value;
    setInput(textInput);
    dispatch(setLoadOption(true));
    setGeocoderInput(textInput, typeSearch);
  };

  useEffect(() => {
    setInput(value);
    return () => dispatch(setLoadOption(false));
  }, [value]);

  useEffect(() => {
    if (input && input !== '') {
      dispatch(setLoadOption(true));
      setGeocoderInput(input, typeSearch);
    }
  }, [typeSearch]);

  return (
    <input 
      className="form-text" 
      type="text" 
      style={{cursor: showPoi && 'pointer'}}
      readOnly={showPoi}
      autoFocus={autofocus}
      name="q" 
      id="search" 
      placeholder={t('search.geocoder_placeholder')} 
      autoComplete='off'
      value={input ?? ''}
      onClick={handlePressInput}
      onChange={handleGeocoderInput}
    />
  );
};

export const GeocoderInput = memo(GeocoderInputComponent, testAreEquals);