import React from 'react';
import { useTranslation } from 'react-i18next';

export const Presentation = () => {

  const {t} = useTranslation();

  return (
    <div>
      <p>
        <b>{t('modalIntroduction.introduction_1')}</b>
      </p>
      <br/>
      <p>
        {t('modalIntroduction.introduction_2')}
      </p>
      <br/>
      <p>
        <b>{t('modalIntroduction.introduction_3')}</b>
      </p>
      <br/>
      <p>
        <b>{t('modalIntroduction.introduction_4')}</b>
      </p>
      <br/>
      <p>
        {t('modalIntroduction.introduction_5')}
      </p> 
    </div>
  );
};