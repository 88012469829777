import { config } from '../config';

const userApi = `${config.url.API_BASE_URL}/users`;
const linkApi = `${config.url.API_BASE_URL}/send_uniq`;

export const updateUser = async (userId, data) => {
  const url = `${userApi}/${userId}`;
  const results = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(
      data
    ),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  }) .then((response) => response.json())
    .then((data) => {
      console.log('data user', data);
      // Handle data
    })
    .catch((err) => {
      console.log('failed to update user', err.message);
    });
  return results;
};

export const getUserData = async (userId) => {
  const url = `${userApi}/${userId}`;
  const results = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  }) .then((response) => response.json())
    .catch((err) => {
      console.log('failed to get info user', err.message);
    });
  return results;
};

export const getUserSurvey = async (userId) => {
  const url = `${userApi}/${userId}/surveys/`;
  const results = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  }) .then((response) => response.json())
    .catch((err) => {
      console.log('failed to get survey user', err.message);
    });
  return results;
};

export const getLinkUnique = async (data) => {
  const results = await fetch(linkApi, {
    method: 'POST',
    body: JSON.stringify(
      data
    ),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  }).then(response => {
    if (response.status === 200) {
      return {
        results: 'success',
        message: "✉️ Un email va vous être envoyé à l'adresse fournie si vous nous l'aviez renseignée au préalable et consenti à ce que nous puissions vous envoyer un mail."
      };
    }
  })
    .catch((err) => {
      return {
        results: 'error',
        message: err
      };
    });
  return results;
};