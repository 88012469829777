import React from 'react';
import { useTranslation } from 'react-i18next';

export const CommentContainer = ({register}) => {
  const {t} = useTranslation();
  return (
    <div className="form-group form-textarea">
      <label htmlFor="comments">{t('survey.comment_explain')}</label>
      <div className="form-input">
        <textarea 
          className="form-text" 
          name="comments" 
          id="comments" 
          maxLength={200} 
          {...register('comment')}
        />
      </div>
      <div className="form-tooltip">
        <small>{t('survey.helper_explain')}</small>
      </div>
    </div>
  );
};