import { useTranslation } from 'react-i18next';

export const getLabel = () => {
  const {t} = useTranslation();

  const getLanguageName = (code) => {
    switch(code) {
      case 'en':
        return 'English';
      case 'fr':
        return 'Français';
      default:
        return '';
    }
  };

  const getLabelCriteria = criteria => {
    switch (criteria) {
      case 'practicability':
        return t('survey.practicability_label');
      case 'comfort':
        return t('survey.comfort_label');
      case 'security':
        return t('survey.security_label');
      default:
        return t('survey.unknown_label');
    }
  };

  const getLabelMenu = type => {
    switch (type) {
      case 'presentation':
        return t('menu.present_label');
      case 'link':
        return t('menu.lost_link_label');
      case 'contact':
        return t('menu.contact_label');
      case 'condition':
        return t('menu.data_label');
      case 'legal':
        return t('menu.legal_label');
      case 'language':
        return t('menu.language_label');
      default:
        return;
    }
  };

  return {
    getLabelCriteria,
    getLabelMenu,
    getLanguageName
  };

};
