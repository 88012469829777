import React from 'react';

import { useTranslation } from 'react-i18next';

export const Legal = () => {

  const {t} = useTranslation();

  return (
    <div className='legal-note'>
      <div className='info-legal'>
        <h2>{t('modalLegal.editor_title')}</h2>  
        <p>
          {t('modalLegal.editor_info')}
        </p>
        <p>
          {t('modalLegal.editor_address')}
        </p>
        <p>
          {t('modalLegal.editor_siren')}
        </p>
        <p>
          {t('modalLegal.editor_tva')}
        </p>
        <p>
          {t('modalLegal.editor_mail')}
        </p>
      </div>
      <div className='info-legal'>
        <h2>{t('modalLegal.hosting_title')}</h2>  
        <p>
          {t('modalLegal.hosting_info')}
        </p>
      </div>      
    </div>
  );
};