import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ForwardButton } from '../components/Button/ForwardButton';
import { config } from '../config';


export const WelcomeContainer = ({forwardCallback}) => {

  const {t, i18n} = useTranslation();

  const listSurveys = useSelector(state => state.user.surveyAnswers);
  const textIntro = useSelector(state => state.init.introduction);
  const cityName = useSelector(state => state.init.city);

  const someSurveysExecuted = Array.isArray(listSurveys) && listSurveys.length > 0; 

  const [introText, setIntroText] = useState(undefined);

  const renderTitleWelcome = () => {
    if (someSurveysExecuted) {
      return t('welcome.hello_with_survey');
    } else {
      return t('welcome.hello_no_survey');
    }
  };

  const renderTextIntroduction = (introduction) => {
    if (typeof introduction == 'string') {
      const newText = introduction.split('/').map((elem, index) => (<p key={index}>{elem}</p>));
      return newText;
    } else {
      return '';
    }
  };

  const renderTextReturnWelcome = () => {
    return (
      <>
        <p>{t('welcome.thank_you_text', {cityName: cityName})}</p>
        <p>{t('welcome.continue_text', {cityName: cityName})}</p>
      </>
    );
  };

  useEffect(() => {
    setIntroText(textIntro[i18n.language]);
    return () => {
      setIntroText(undefined);
    };
  }, [i18n.language, textIntro]);


  return (
    <>
      <h1>
        <span className="hidden">Enquête participative sur la qualité de marche</span>
        <img src={`${config.url.API_BASE_URL}/logo`} alt="city-logo" />
      </h1>
      <div className="heading">{renderTitleWelcome()}</div>
      {someSurveysExecuted ? (
        renderTextReturnWelcome()
      ) : (
        renderTextIntroduction(introText)
      )}
      <ForwardButton label={t('welcome.start_button')} handleClick={forwardCallback} />
    </>
  );
};