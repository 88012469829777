import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Modal  from '@mui/material/Modal';

import { ForwardButton } from '../components/Button/ForwardButton';
import { closeSummary } from '../slices/summarySlice';
import { openSearch } from '../slices/searchSlice';
import { useResponsive } from '../hooks/useResponsive';
import { resetPoi } from '../slices/poiSlice';
import { InfoSurveyByUser } from '../components/InfoSurveyByUser';
import { config } from '../config';
import { useTranslation } from 'react-i18next';

export const SummarySurveyContainer = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {isMobile} = useResponsive();
  
  const style = {
    modalStyle: {
      '&.MuiModal-root': {
        top: isMobile ? 'unset' : 0 
      }
    }
  };

  const summarySurveyShow = useSelector(state => state.summary.open);

  /**
   * Do not execute refetch of info in this state because
   * it will cause unnecessary re render on the map => causing
   * flashing / dissepear of features on map
   */

  const handleRevaluate = () => {
    dispatch(resetPoi());
    dispatch(closeSummary());
    dispatch(openSearch());
  };
  return (
    <Modal 
      open={summarySurveyShow} 
      sx={style.modalStyle} 
      onClose={handleRevaluate}
      disablePortal
    >
      <div className="overlay flow-half">
        <img src={`${config.url.API_BASE_URL}/logo`} alt="logo ville" />
        <p>{t('summary.thank_you')} 🙌</p>
        <div>
          <InfoSurveyByUser />
        </div>
        <ForwardButton label={t('summary.revaluate_button')} handleClick={handleRevaluate} /> 
      </div>
    </Modal>
  );
};