import React from 'react';

import { ForwardButton } from '../../components/Button/ForwardButton';
import { BackwardButton } from '../../components/Button/BackwardButton';
import { useTranslation } from 'react-i18next';

export const FooterSurvey = ({
  steps, 
  stepEvaluation, 
  surveyWithComment,
  handleOnClickForward, 
  handleOnClickBackward
}) => {

  const {t} = useTranslation();

  const renderButtonForward = () => {
    if (stepEvaluation === 'practicability' || stepEvaluation === 'comfort') {
      return (
        <ForwardButton 
          label={`${t('button.next_step')} (${steps.indexOf(stepEvaluation) + 1}/3)`} 
          handleClick={handleOnClickForward}
        />
      );
    } else if (surveyWithComment) {
      return (
        <ForwardButton 
          label={`${t('button.next_step')} (${steps.indexOf(stepEvaluation) + 1}/3)`} 
          handleClick={handleOnClickForward}
        />
      );
    } else {
      return (
        <button 
          form='survey-form'
          type='submit'
          className='btn btn-block btn-pill btn-primary btn-fill-h'
        >
          <span>{t('button.finish_survey')}</span>
          <span className='icon icon icon-arrow-right'></span>
        </button>
      );
    }
  };
  return (
    <footer className='survey-actions grid xs:grid-col-1 md:grid-col-12'>
      <div className='md:order-1 md:col-start-7 md:col-end-12'>
        {renderButtonForward()}
      </div>
      <div className='md:col-start-1 md:col-end-3'>
        <BackwardButton label={stepEvaluation === 'practicability' ? t('button.close_survey') : t('button.previous_step')} handleClick={handleOnClickBackward}/>
      </div>
    </footer>
  );
};