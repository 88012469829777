import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { getClassIconTypeSearch } from '../utils/IconUtils';
import { setTypeSearch } from '../slices/searchSlice';
import { useTranslation } from 'react-i18next';

export const TypeSearchSelectContainer = () => {

  const dispatch = useDispatch();
  const {t} = useTranslation();

  const typeSearch = useSelector(state => state.search.type);

  const listTypeSearch = [
    {id: 'all', label: `${t('search.type_all')}`},
    {id: 'way', label: `${t('search.type_way')}`},
    {id: 'station', label: `${t('search.type_station')}`},
    {id: 'parking', label: `${t('search.type_parking')}`},
    {id: 'other', label: `${t('search.type_other')}`},
  
  ];

  return (
    <menu className='button-list'>
      {listTypeSearch.map(elem => (
        <li key={elem.id}>
          <button 
            onClick={() => dispatch(setTypeSearch(elem.id))}
            className={elem.id === typeSearch ? 'btn btn-primary btn-sm' : 'btn btn-secondary btn-sm'}
          >
            <span className={`icon icon-${getClassIconTypeSearch(elem.id)}`}></span>
            <span>{elem.label}</span>
          </button>
        </li>
      ))}
    </menu>
  );

};