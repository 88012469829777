import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getRankBySurveys, surveyRestFromChange } from '../utils/rankUtils';
import { openSurvey, setStepSurvey } from '../slices/surveySlice';
import { closeInfoUser, setModify } from '../slices/userSlice';
import { closeInfoPoi } from '../slices/poiSlice';
import { startResetSelected } from '../slices/mapSlice';
import { Trans, useTranslation } from 'react-i18next';

export const InfoSurveyByUser = ({showModify = false}) => {

  const userSurveysList = useSelector(state => state.user.surveyAnswers);
  const showPoi = useSelector(state => state.poi.open);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  
  const [numberSurveyRested, setNumberSurveyRested] = useState(0);
  const [percentageRested, setPercentageRested] = useState(0);

  const handleOpenModifyUser = () => {
    if (showPoi) {
      dispatch(closeInfoPoi());
      dispatch(startResetSelected());
    }
    dispatch(closeInfoUser());
    dispatch(setModify(true));
    dispatch(setStepSurvey('userInfo'));
    dispatch(openSurvey());
  };

  useEffect(() => {
    const numberSurveyRest = surveyRestFromChange(userSurveysList.length);
    setNumberSurveyRested(numberSurveyRest);
    setPercentageRested((numberSurveyRest * 100) / 5);
    return () => {
      setNumberSurveyRested(0);
      setPercentageRested(0);
    };
  }, [userSurveysList]);
  
  return (
    <>
      <div className={`rank level-${getRankBySurveys(userSurveysList.length)}`}>
        {t('summary.contributor')}
      </div>
      <div className="heading">
        <Trans 
          i18nKey='summary.number_evaluated'
          values={{
            number: userSurveysList.length
          }}
          components={{
            span: <span className="txt-primary" />
          }}
        />
      </div>
      <div className="progress" id="to-next-rank" style={{'--missing': `${percentageRested}%`}}></div>
      {showModify && (
        <div className='user-modify'>
          <button style={{textDecoration: 'underline'}} className='btn-update' onClick={handleOpenModifyUser}>
            {t('summary.modify_user_data_button')}
          </button>
        </div>
      )}
      <p>
        <Trans
          i18nKey='summary.notice'
          values={{
            number: numberSurveyRested
          }}
        />
      </p>
      <div className={`rank level-${getRankBySurveys(userSurveysList.length) + 1}`}>
        {t('summary.contributor')}
      </div>
    </>
  );
};