import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Collapse from '@mui/material/Collapse';
import Skeleton from '@mui/material/Skeleton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { GeocoderInput } from '../components/GeocoderInput';
import { SecondaryButton } from '../components/Button/SecondaryButton';
import { closeInfoPoi, resetPoi, setTypePoiSelected } from '../slices/poiSlice';
import { openSurvey } from '../slices/surveySlice';
import { hideOptionsList, openSearch, showOptionsList } from '../slices/searchSlice';
import { setAddStreet, startResetSelected } from '../slices/mapSlice';
import { StreetView } from '../components/StreetView';
import { closeNoti } from '../slices/notiSlice';
import { AlertNoti } from '../components/AlertNoti';
import { useResponsive } from '../hooks/useResponsive';
import { useTranslation } from 'react-i18next';

export const InfoPoiSelected= ({expanded = true, expandedCallback}) => {

  const { isDesktop } = useResponsive();
  const {t} = useTranslation();

  const labelSelected = useSelector(state => state.poi.labelPoi);
  const typePoiSelected = useSelector(state => state.poi.typePoiSelected);
  const idView = useSelector(state => state.poi.idImageView);
  const loadingImage = useSelector(state => state.poi.loadingView);
  const notiShow = useSelector(state => state.noti.open);

  const dispatch = useDispatch();

  const [imageLoaded, setImageLoaded] = useState(false);

  const handlePoiSurvey = (e) => {
    e.preventDefault();
    dispatch(closeInfoPoi());
    dispatch(openSurvey());
  };

  const returnToSearchWithOutOptions = (e) => {
    e.preventDefault();
    dispatch(startResetSelected());
    dispatch(resetPoi());
    dispatch(hideOptionsList());
    dispatch(openSearch());
  };

  const returnToSearchWithOptions = (e) => {
    e.preventDefault();
    dispatch(startResetSelected());
    dispatch(closeInfoPoi());
    dispatch(showOptionsList());
    dispatch(openSearch());
  };


  const handleSetAllStreet = (e) => {
    e.preventDefault();
    dispatch(setAddStreet(true));
    dispatch(closeInfoPoi());
    dispatch(setTypePoiSelected('street'));
  };

  const onCloseNoti = () => {
    dispatch(closeNoti());
  };

  const renderExpandComponent = () => {
    if (!isDesktop) {
      if (expanded) {
        return (
          <div className='drag-container'>
            <div className='drag-component' />
          </div>
        );
      } else {
        return (
          <button className='btn-expand' onClick={expandedCallback}>
            <KeyboardArrowUpIcon className='icon-expand' />
          </button>
        );
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    if (!loadingImage) {
      setImageLoaded(true);
    } else {
      setImageLoaded(false);
    }
  }, [loadingImage]);

  useEffect(() => {
    if (notiShow) {
      setTimeout(() => {
        dispatch(closeNoti());
      }, [3000]);
    }
  }, [notiShow]);

  const renderViewerImage = (viewId) => {
    if (viewId) {
      return (
        <StreetView idView={viewId} />
      );
    } else {
      return (
        <p>{t('selected.no_image')}</p>
      );
    }
  };

  return (
    <React.Fragment>
      <Collapse in={notiShow} collapsedSize={0} mountOnEnter unmountOnExit>
        <AlertNoti closeCallback={onCloseNoti} />
      </Collapse>
      {renderExpandComponent()}
      <div className="flex align-stretch gap-1">
        <button className="btn btn-empty btn-icon btn-icon-lg squeeze" onClick={returnToSearchWithOutOptions}>
          <span className="icon-caret-left"></span>
        </button>
        <div className="grow">
          <div className="form-group">
            { expanded ? (
              <GeocoderInput 
                value={labelSelected}
                handlePressInput={returnToSearchWithOptions}
              />
            )  : (
              <p className="form-text" onClick={expandedCallback}>   
                {labelSelected}
              </p>      
            )} 
          </div>
        </div>
      </div>
      {expanded && (
        <figure className="map-view square">
          {imageLoaded ? (
            <>
              {renderViewerImage(idView)}
            </>
          ) : (
            <Skeleton 
              className='loading-view'
              animation='wave'
              variant="rectangular"
            />
          )}
        </figure>
      )}
      {expanded && (
        <SecondaryButton label={t('selected.rate_portion_button')} handleClick={handlePoiSurvey} />
      )}
      {typePoiSelected === 'portion' && expanded && labelSelected && labelSelected !== '' && (
        <SecondaryButton label={t('selected.rate_street_button')} handleClick={handleSetAllStreet} />
      )}
      {/* {expanded && (
        <figure className="map-view square">
          {imageLoaded ? (
            <>
              {renderViewerImage(idView)}
            </>
          ) : (
            <Skeleton 
              className='loading-view'
              animation='wave'
              variant="rectangular"
            />
          )}
        </figure>
      )} */}
    </React.Fragment>
  );
};