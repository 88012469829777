import React from 'react';

import ReactCountryFlag from 'react-country-flag';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';

import { getLabel } from '../hooks/useLabel';

const listCountry = [
  {id: 'fr', flag: 'FR'},
  {id: 'en', flag: 'GB'},
];

export const LanguageSelector = () => {

  const {i18n} = useTranslation();
  const {  getLanguageName } = getLabel();


  // eslint-disable-next-line no-unused-vars
  const handleChangeLanguage = (value) => {
    i18n.changeLanguage(value);
  };
  

  return (
    <div id='language-selector'>
      {listCountry.map(elem => (
        <div 
          key={elem.id} 
          id={`lang-${elem.id}`} 
          onClick={() => handleChangeLanguage(elem.id)}
          className={`lang-option ${elem.id === i18n.language ? 'active' : ''}`}
        >
          <div className='label-container'>
            <div>
              <ReactCountryFlag 
                className='flag_country' 
                countryCode={elem.flag} />  
            </div>
            <div className='label-lang'>
              <p>
                {getLanguageName(elem.id)}
              </p>
            </div>
          </div>
          <div className={`tick-container ${elem.id === i18n.language ? 'active' : ''}`}>
            <CheckCircleIcon className='tick-icon'/>
          </div>
        </div>
      ))}
    </div>
  );
};