import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const Condition = () => {

  const {t} = useTranslation();

  return (
    <div className='personal-data'>
      <div className='personal-data-section'>
        <h2>
          {t('modalData.data_usage_title')}
        </h2>
        <p>
          {t('modalData.data_usage_1')}
        </p>
        <p>
          {t('modalData.data_usage_2')}   
        </p>
        <p>
          {t('modalData.data_usage_3')}    
        </p>
      </div>
      <div className='personal-data-section'>
        <h2>
          {t('modalData.your_right_title')}
        </h2>
        <p className='cookie-explain'>
          {t('modalData.data_protection_label')}
        </p>
        <p>
          {t('modalData.coord_ref')}
        </p>
        <p>
          Bertrand GERVAIS<br/>
          Email : contact@someware.fr
        </p>
        <p className='cookie-explain'>
          {t('modalData.right_cnil_label')}
        </p>
        <p>
          {t('modalData.right_cnil_intro')}
        </p>
        <p>
        CNIL – Service des plaintes<br/>
        3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07<br/>
          {t('modalData.phone_cnil')}
        </p>
      </div>
      <div className='personal-data-section'>
        <h2>
          {t('modalData.cookies_label')}
        </h2>
        <p>
          <Trans 
            i18nKey='modalData.cookies_text_1'
            components={{
              a: <a href='https://www.cnil.fr/fr/definition/cookie' target='_blank' rel="noreferrer" />
            }}
          />
        </p>
        <div className='cookies-def'>
          <p className='cookies-def-headers'>
            {t('modalData.cookies_def_label')}
          </p>
          <p>
            {t('modalData.cookies_def_explain')}
          </p>
          <a href='https://www.cnil.fr/fr/cookies-et-autres-traceurs/comment-se-proteger/maitriser-votre-navigateur' target='_blank' rel="noreferrer">
            {t('modalData.cookies_def_link')}
          </a>
        </div>
      </div>
      <div className='personal-data-section'>
        <h2>{t('modalData.type_cookies_label')}</h2>
        <p className='cookie-explain'>
          {t('modalData.cookies_necessary_title')}
        </p>
        <p>
          {t('modalData.cookies_necessary_1')}
        </p>
        <p>
          {t('modalData.cookies_necessary_2')}
        </p>
      </div>
      <div className='personal-data-section'>
        <p className='cookies-technique-headers'>
          {t('modalData.cookies_technique_label')}
        </p>
        <table className='table-cookies'>
          <thead>
            <tr>
              <th>{t('modalData.cookie_name')}	</th>
              <th>{t('modalData.cookie_goal')}</th>
              <th>{t('modalData.cookie_duration')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>mly_cb</td>
              <td>{t('modalData.goal_value')}</td>
              <td>{`1 ${t('modalData.year')}`}</td>
            </tr>
          </tbody>
        </table>
        <p className='cookies-technique-headers'>
          {t('modalData.cookie_measure_label')}
        </p>
        <table className='table-cookies'>
          <thead>
            <tr>
              <th>{t('modalData.cookie_name')}	</th>
              <th>{t('modalData.cookie_goal')}</th>
              <th>{t('modalData.cookie_duration')}</th>
            </tr>
          </thead>
          <tbody>
          </tbody>
        </table>
      </div>
    </div>
  );
};