import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { OptionButton } from '../../components/Button/OptionButton';
import { getClassIconTypeSearch } from '../../utils/IconUtils';
import { closeSearch } from '../../slices/searchSlice';
import { openInfoPoi, setLabelPoi } from '../../slices/poiSlice';
import { setStreetToPoint } from '../../slices/mapSlice';
import { useTranslation } from 'react-i18next';

export const OptionSearchContainer = ({listOptions}) => {

  const dispatch = useDispatch();
  const {t} = useTranslation();
  
  const typeSearch = useSelector(state => state.search.type);
  const loading = useSelector(state => state.search.loadOption);
  const filteredListOption = typeSearch === 'all' ? listOptions : listOptions.filter(elem => elem.type === typeSearch);
  const resultListOptions = [  
    {id: 'my_location', label: `${t('search.geolocation')}`, type: 'my_location'},
    ...filteredListOption
  ];

  const handleOnClickOption = (coord, label) => {
    if (Array.isArray(coord) && coord.length > 0) {
      dispatch(setStreetToPoint(coord));
      dispatch(setLabelPoi(label));
      dispatch(closeSearch());
      dispatch(openInfoPoi());
    } else {
      return;
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <div className='list-select-loading'>
          Chargement...
        </div>
      ) : (
        <ul className='list-select h-60 has-icons flow-half'>
          {resultListOptions && resultListOptions.map((elem, index) => (
            <li key={index}>
              <OptionButton 
                geolocation={elem.type === 'my_location'} 
                label={elem.label} 
                icon={getClassIconTypeSearch(elem.type)}
                handleClick={() => handleOnClickOption(elem.point, elem.label)}
              />
            </li>
          ))}
        </ul>
      )}
    </React.Fragment>
  );
};